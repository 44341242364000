import axios from "axios";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bounce, toast } from "react-toastify";
import {
  addHomeInquiryProducts,
  setHomeInquiryCustomer,
} from "../../features/order/homeInquirySlice";
import {
  addStoreInquiryProducts,
  setStoreInquiryCustomer,
} from "../../features/order/storeInquirySlice";

export const InquiryPopup = (props) => {
  const dispatch = useDispatch();
  const modalRef = useRef(null);
  const homeInquiry = useSelector((state) => state.homeInquiry);
  const storeInquiry = useSelector((state) => state.storeInquiry);
  const homeInquiryCustomer = useSelector(
    (state) => state.homeInquiry.customer
  );
  const storeInquiryCustomer = useSelector(
    (state) => state.storeInquiry.customer
  );
  const storeInquiryCustomerPhone = storeInquiryCustomer?.phone || "";
  console.log(homeInquiryCustomer?.phone);
  useEffect(() => {
    if (homeInquiryCustomer) {
      customerFormik.setFieldValue("phone", homeInquiryCustomer.phone);
      setTimeout(() => customerFormik.setFieldTouched("phone", true));
    }
  }, [homeInquiryCustomer]);
  const [orderType, setOrderType] = useState(null);
  const product = props.data?.product || null;
  const errors = {};
  const customerFormik = useFormik({
    initialValues: {
      phone: "",
    },
    onSubmit: async function (values) {
      try {
        console.log("first");
        const response = await axios(
          process.env.REACT_APP_BASE_URL + `/customer/mobile/${values.phone}`,
          {
            method: "GET",
            data: customerFormik.values,
            headers: {
              "x-api-key": process.env.REACT_APP_API_KEY,
            },
          }
        );
        console.log(response.data.customer);

        if (orderType == "HOME INQUIRY") {
          const promise = dispatch(
            setHomeInquiryCustomer(response.data.customer)
          );
          console.log(promise);
          handleHomeInquiry(promise.payload);
        } else if (orderType == "STORE INQUIRY") {
          const promise = dispatch(
            setStoreInquiryCustomer(response.data.customer)
          );
          console.log(promise);
          handleStoreInquiry(promise.payload);
        }
      } catch (err) {
        console.log(err);
        toast.error(err.response ? err.response.data.message : err.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    },
    validate: (values) => {
      if (!values.phone) {
        errors.phone = "Required";
      } else if (!/^[0-9]{10}$/.test(values.phone)) {
        errors.phone = "Invalid mobile number";
      }

      return errors;
    },
  });

  if (!product) {
    return null;
  }

  const handleHomeInquiry = (customer) => {
    props.setShowInquiryTray(true);
    props.setShowInquiryPopup(false);
    props.setOrderType("Home Inquiry");
    const isInCart = homeInquiry.products.some(
      (item) => item.product_id === product._id
    );
    // check if the mobile number added into the inquiry tray is same as the current customer if not then throw error
    const inCartPhone = homeInquiry.products.some(
      (item) => item.phoneNumber === customer.phone
    );
    if (!inCartPhone && homeInquiry.products.length > 0) {
      toast.error(
        "There are produts in the Inquiry tray with other customer, empty the tray if you want to add   ",
        {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }
      );
      return;
    }
    if (!isInCart) {
      // max 30 products can be added into inquiry
      if (homeInquiry.products.length >= 30) {
        toast.error("Maximum 30 products can be added to inquiry tray", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        return;
      }
      dispatch(
        addHomeInquiryProducts([
          ...homeInquiry.products,
          {
            images: product.image,
            variants: product.variants,
            attributes: product.attributes,
            orderStatus: "Placed",
            product_id: product._id,
            name: product.title.en,
            sku: product.sku,
            isCombination: product.isCombination,
            quantity: 1,
            price: product.prices.price,
            inquiryStatus: "Placed",
            phoneNumber: customer.phone,
            customerId: customer._id,
          },
        ])
      );
    } else {
      toast.error("Product already added to inquiry tray", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }

    console.log("home inquiry");
  };

  const handleStoreInquiry = (customer) => {
    props.setShowInquiryTray(true);
    props.setShowInquiryPopup(false);
    props.setOrderType("Store Inquiry");
    // const isInCart = storeInquiry.products.some(item => item.product_id === product._id);
    // product is in cart only whem the product_id and phonNumber is same
    const isInCart = storeInquiry.products.some(
      (item) =>
        item.product_id === product._id && item.phoneNumber === customer.phone
    );
    if (!isInCart) {
      // max 30 products can be added into inquiry
      if (storeInquiry.products.length >= 30) {
        toast.error("Maximum 30 products can be added to inquiry tray", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        return;
      }

      dispatch(
        addStoreInquiryProducts([
          ...storeInquiry.products,
          {
            images: product.image,
            variants: product.variants,
            attributes: product.attributes,
            orderStatus: "Placed",
            product_id: product._id,
            name: product.title.en,
            sku: product.sku,
            isCombination: product.isCombination,
            quantity: 1,
            price: product.prices.price,
            inquiryStatus: "Placed",
            phoneNumber: customer.phone,
            customerId: customer._id,
          },
        ])
      );
    } else {
      toast.error("Product already added to inquiry tray", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }

    console.log("store inquiry");
  };

  return (
    <div
      className="inquiry-popup"
      
    >
      <div className="popup-body" ref={modalRef}>
        <div
        style={{fontSize:"28px"}}
          className="close"
          onClick={() => {
            props.setShowInquiryPopup(false);
          }}
        >
          &times;
        </div>
        <div className="header">Inquiry Details</div>
        {/* <div className="color-option">Color</div> */}
        <div className="form">
          <div className="title">{product?.name}</div>
          {product?.price && (
            <div className="prince">&#8377;{product?.price}</div>
          )}
          {product?.prices?.price && (
            <div className="prince">&#8377;{product?.prices?.price}</div>
          )}
          <div className="form-item">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="#5f6368"
            >
              <path d="M757.23-160q-101.15 0-207.38-50.65-106.23-50.66-197.39-142.2-91.15-91.53-141.81-197.38Q160-656.08 160-757.23q0-18.33 12-30.55Q184-800 202-800h98.92q16.31 0 28.46 10.27 12.16 10.27 16.47 26.35L365.69-668q2.77 16.77-1 29.31t-13.31 20.54l-87.76 81.84q24.61 44.69 54.42 83.04 29.81 38.35 63.58 72.65 34.84 34.85 75 64.81 40.15 29.96 88.15 56.58l85.54-87.08q9.77-10.54 21.96-13.88 12.19-3.35 26.96-1.35l84.15 17.23q16.31 4 26.47 16.43Q800-315.46 800-299.38V-202q0 18-12.22 30t-30.55 12ZM244.85-573.85l76.77-70.61q3.84-3.08 5-8.46 1.15-5.39-.39-10l-17.77-84.77q-1.54-6.16-5.38-9.23-3.85-3.08-10-3.08H211q-4.62 0-7.69 3.08-3.08 3.07-3.08 7.69 1.15 41 12.85 85.61 11.69 44.62 31.77 89.77Zm338 333.39q40.53 20.08 86.42 29.69 45.88 9.62 79.96 10.31 4.62 0 7.69-3.08 3.08-3.08 3.08-7.69v-80.31q0-6.15-3.08-10-3.07-3.84-9.23-5.38l-74-15.16q-4.61-1.54-8.07-.38-3.47 1.15-7.31 5l-75.46 77Zm-338-333.39Zm338 333.39Z" />
            </svg>
            <input
              type="text"
              placeholder="Phone number"
              name="phone"
              value={customerFormik.values.phone}
              onChange={customerFormik.handleChange}
            />
            {customerFormik.errors.phone ? (
              <div className="error">{customerFormik.errors.phone}</div>
            ) : null}
          </div>
        </div>
        <div className="btns">
          {/* <div className="home-inquiry" onClick={() => {
          setOrderType("HOME INQUIRY");
          customerFormik.handleSubmit();
        }}>HOME INQUIRY</div> */}
          <div
            className="store-inquiry"
            onClick={() => {
              setOrderType("STORE INQUIRY");
              customerFormik.handleSubmit();
            }}
          >
            STORE INQUIRY
          </div>
        </div>
      </div>
      <div className="overlay" onClick={() => props.setShowInquiryPopup(false)}></div>
    </div>
  );
};
