import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./ProductCard.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  setSearchQuery,
  setShowSearch,
} from "../../features/search/searchSlice";
function ProductCard(props) {
  const [stock, setStock] = useState(0);
  // const stock = props.product.stock;
  // const qty = props.product.quantity;
  // console.log("stock", stock)
  // console.log("qty", qty)
  useEffect(() => {
    if (props.product.stock) {
      setStock(props.product.stock);
    }
    if (props.product.quantity) {
      setStock(props.product.quantity);
    }
  }, [props.product.stock, props.product.quantity]);

  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const location = useLocation();
  useEffect(() => {
    const generateOptions = () => {
      const newOptions = [];
      // stock can be max 3 in this case
      const max = stock > 3 ? 3 : stock;
      for (let i = 0; i <= max; i++) {
        newOptions.push(
          <option key={i} value={i}>
            {i}
          </option>
        );
      }
      setOptions(newOptions);
      setIsLoading(false);
    };

    // Simulating some loading time
    const timeout = setTimeout(() => {
      generateOptions();
    }, 1000);

    return () => clearTimeout(timeout);
  }, [stock]);
  const navigate = useNavigate();

  // take handleSelectChange from prop and use it to handle the select change
  const handleSelectChange = (e) => {
    props.handleSelectChange(e, props.product);
  };

  const redirctUrl =
    props.storeType === "Virtual"
      ? `/product/virtual/${props.product._id}`
      : `/product/instore/${props.product.product_id}`;
  //sample url https://res.cloudinary.com/dzsnzv0at/image/upload/v1716377375/product/br10074919-135C1png4.png
  //add if_w_gt_300/w_300/if_end after upload to get the image of 300

  var image = null;
  switch (props.storeType) {
    case "Virtual":
      image = props.product?.image[0] ? props.product?.image[0] : null;
      if (image) {
        var transformedImage = image.replace(
          "upload/",
          "upload/if_w_gt_400/w_400/if_end/"
        );
      } else {
        var transformedImage = null;
      }
      break;
    case "In Store":
      image = props.product?.images[0];
      if (image) {
        var transformedImage = image.replace(
          "upload/",
          "upload/if_w_gt_400/w_400/if_end/"
        );
      } else {
        var transformedImage = null;
      }
      break;
    default:
      var transformedImage = null;
  }

  //on click on sku copy the sku to clipboard
  const copyToClipboard = (e) => {
    navigator.clipboard.writeText(props.product.sku);
    e.target.innerText = "Copied!";
    setTimeout(() => {
      e.target.innerText = "SKU : " + props.product.sku;
    }, 1000);
  };
  const showSearch = useSelector((state) => state.search.showSearch);
  const dispatch = useDispatch();

  const selectedProducts = useSelector(
    (state) => state.selectedProducts.selectedProducts
  );

  const selectedProduct = selectedProducts.find(
    (p) => p.product_id === props?.product._id
  );
  const selectedQuantity = selectedProduct ? selectedProduct.quantity : "";

  // ====================== New arrival ================================
  const [showNewArrival, setShowNewArrival] = useState(false);
  const interval = 1000 * 60 * 60 * 24 * 30; // 30 days
  useEffect(() => {
    if (props?.product?.createdAt) {
      const productDate = new Date(props?.product?.createdAt);
      const currentDate = new Date();
      const diff = currentDate - productDate;
      if (diff < interval) {
        setShowNewArrival(true);
      }
    }
  }, [props?.product?.createdAt]);

  const calculateDiscountPercentage = (originalPrice, discount) => {
    return Math.round(((discount / originalPrice) * 100).toFixed(2)); // 2 decimal places
  };

  return (
    <div
      className="card"
      style={{
        border: `1px solid ${
          selectedQuantity ? "var(--color2)" : "var(--secondary-bg-color)"
        }`,
      }}
    >
      <div
        style={{ visibility: showNewArrival ? "visible" : "hidden" }}
        className="new-arrival-label"
      >
        NEW ARRIVAL
      </div>
      {calculateDiscountPercentage(
        props?.product?.prices?.originalPrice,
        props?.product?.prices?.discount
      ) !== 0 &&
        !showNewArrival && (
          <div className="offer_ribbon" style={{ fontWeight: "bolder" }}>
            {calculateDiscountPercentage(
              props?.product?.prices?.originalPrice,
              props?.product?.prices?.discount
            )}
            %<span style={{ color: "white", fontWeight: "bolder" }}>Off</span>
          </div>
        )}
      <div className="top">
        {/* <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M344-364.461 228.461-480 344-595.539 371.539-567l-87 87 87 87L344-364.461Zm272 0L588.461-393l87-87-87-87L616-595.539 731.539-480 616-364.461ZM224.615-160Q197-160 178.5-178.5 160-197 160-224.615V-360h40v135.385q0 9.23 7.692 16.923Q215.385-200 224.615-200H360v40H224.615ZM600-160v-40h135.385q9.23 0 16.923-7.692Q760-215.385 760-224.615V-360h40v135.385Q800-197 781.5-178.5 763-160 735.385-160H600ZM160-600v-135.385Q160-763 178.5-781.5 197-800 224.615-800H360v40H224.615q-9.23 0-16.923 7.692Q200-744.615 200-735.385V-600h-40Zm600 0v-135.385q0-9.23-7.692-16.923Q744.615-760 735.385-760H600v-40h135.385Q763-800 781.5-781.5 800-763 800-735.385V-600h-40Z"/></svg> */}
        {props.product?.quantity > 0 && (
          <div
            className={
              (location.pathname === "/inventory/virtual" ||
                location.pathname === "/inventory/in-store") &&
              props.storeType === "In Store"
                ? "count"
                : "count hide"
            }
          >
            {props.product?.quantity}
          </div>
        )}
      </div>
      <div
        className="middle pointer"
        key={props.product._id}
        onClick={() => {
          dispatch(setShowSearch(false));
          dispatch(setSearchQuery(" "));
          // next line is not working
          document.querySelector("#search-input").value = "";
          navigate(redirctUrl);
        }}
      >
        <img
          src={transformedImage ? transformedImage : "/Netre-logo.svg"}
          className="product-image"
          alt="Product"
          title="Product"
          loading="lazy"
        />
      </div>
      <div className="bottom">
        {props.product.name && (
          <div
            className={
              (location.pathname === "/inventory/virtual" ||
                location.pathname === "/inventory/in-store") &&
              props.storeType === "Virtual"
                ? "title qty-present"
                : "title"
            }
          >
            {props.product?.name}
          </div>
        )}
        <div
          className={
            location.pathname === "/inventory/virtual" ||
            location.pathname === "/inventory/in-store" ||
            showSearch
              ? "sku show qty-present"
              : "sku"
          }
          onClick={copyToClipboard}
        >
          SKU : {props.product.sku}
        </div>
      </div>
      {props.product?.quantity === 0 ||
        props.product?.quantity === "0" ||
        (Number(props.product?.quantity) <= 0 && (
          <div
            className="sold-out"
            onClick={() => {
              navigate(redirctUrl);
            }}
          >
            <div className="div">SOLD OUT</div>
          </div>
        ))}
      {Number(stock) <= 0 && (
        <div
          className="sold-out"
          onClick={() => {
            navigate(redirctUrl);
          }}
        >
          <div className="div">SOLD OUT</div>
        </div>
      )}
      {Number(stock) > 0 &&
        (location.pathname === "/inventory/virtual" ||
          location.pathname === "/inventory/in-store") &&
        (props.operation === "Add" ||
          props.operation === "Return" ||
          props.operation === "Exchange") && (
          <div className="quantity">
            <div className="label">Qty</div>
            <div className="dropdown">
              <select
                name="add-quantity"
                id="add-quantity"
                onChange={handleSelectChange}
                value={selectedQuantity}
              >
                {isLoading ? <option value="">Loading...</option> : options}
              </select>
            </div>
          </div>
        )}
    </div>
  );
}

export default ProductCard;
