import { InquiryReview } from './InquiryReview';
import { InquiryPopup } from './InquiryPopup';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addHomeInquiryProducts, removeProductFromHomeInquiry, setHomeInquiryCustomer } from "../../features/order/homeInquirySlice";
import { getUser } from "../../home/Home";
import { useNavigate } from "react-router-dom";
import { useAddInquiryMutation, useGetProductsQuery, useGetRetailerInfoQuery } from "../../features/product/productSlice";
import { el } from "@faker-js/faker";
import { Bounce, toast } from "react-toastify";
import { addStoreInquiryProducts, removeProductFromStoreInquiry } from "../../features/order/storeInquirySlice";
import { useFormik } from "formik";
import axios from "axios";
import './InquiryFlow.scss'

export function InquiryFlow(props){
    console.log("------------", props.data)
    const [showInquiryPopup, setShowInquiryPopup] = useState(false);
    const [showInquiryTray, setShowInquiryTray] = useState(false);
    const [showInquiryReview, setShowInquiryReview] = useState(false);
    useEffect(() => {
        setShowInquiryPopup(props.showInquiryPopup);
      }, [props.showInquiryPopup]);
    useEffect(() => {
        setShowInquiryTray(props.showInquiryTray);
      }, [props.showInquiryTray]);
      useEffect(() => {
        props.setShowInquiryTray(showInquiryTray);
      }, [showInquiryTray]);
    useEffect(() => {
        setShowInquiryReview(props.showInquiryReview);
      }, [props.showInquiryReview]);
    useEffect(() => {
        if(showInquiryReview){
          setShowInquiryPopup(false);
          setShowInquiryTray(false);
          props.setShowProductPage(false);
        }
        props.setShowInquiryReview(showInquiryReview);
      }, [showInquiryReview]);
    const [orderType,  setOrderType] = useState("Store Inquiry");

    return(
      <div className="Inquiry">
        {showInquiryPopup && 
        <InquiryPopup 
            setShowInquiryPopup={props.setShowInquiryPopup} 
            {...props} 
            setShowInquiryTray={props.setShowInquiryTray}
            setOrderType={setOrderType}
            />}
        {showInquiryTray && 
        <InquiryTray {...props} orderType={orderType} setShowInquiryReview={setShowInquiryReview} />}
        <InquiryReview 
        showInquiryReview={showInquiryReview} 
        setShowInquiryReview={setShowInquiryReview}
        setShowProductPage={props.setShowProductPage}
        setShowInquiryTray={props.setShowInquiryTray}
        orderType={orderType}
        />
      </div>
    )
  }
  

  
  
  const InquiryTray = (props) => {
    const [showAddProductsPanel, setShowAddProductsPanel] = useState(false);
    const [products, setProducts] = useState([]);
    console.log("tray", products)
    const dispatch = useDispatch();
  
    const homeInquiry = useSelector((state) => state.homeInquiry);
    const storeInquiry = useSelector((state) => state.storeInquiry);
    const homeInquiryCustomer = useSelector((state) => state.homeInquiry.customer);
    const storeInquiryCustomer = useSelector((state) => state.storeInquiry.customer);
    console.log(homeInquiry)
    const [orderType,  setOrderType] = useState(props.orderType || "Store Inquiry");
    const [selectedTab, setSelectedTab] = useState(props.orderType || "Store Inquiry");
    // const tabNames = ['Home Inquiry', 'Store Inquiry'];
    const tabNames = ['Store Inquiry'];
    const handleTabClick = (tab) => {
      setSelectedTab(tab);
      setOrderType(tab);
    };
    return(
      <div className="inquiry-tray">
        <div className="overlay" onClick={()=>{props.setShowInquiryTray(false)}}></div>
        <div className="right-pane">
          <div className="first d-flex j-sb">
            {/* <div className="title">Home Inquiry Tray</div> */}
            {/* navigation */}
            <div className="container">
              <div className="store-types">
              {tabNames.map(tab => (
                <div
                  key={tab}
                  className={orderType === tab ? 'store-type active' : 'store-type'}
                  onClick={() => handleTabClick(tab)}
                >
                  {tab}
                </div>
              ))}
              <div className="border"></div>
            </div>
            </div>
            <div className="close-btn" onClick={()=>{props.setShowInquiryTray(false)}}>x</div>
          </div>
          <div className="d-flex j-sb a-c">
            {/* {orderType == "Home Inquiry" && <div className="count mont-bold">{homeInquiry.products.length}/30</div>} */}
            {orderType == "Store Inquiry" && <div className="count mont-bold">{storeInquiry.products.length}/30</div>}
            {/* {orderType == "Home Inquiry" && <div className="clear-tray" onClick={()=>{dispatch(addHomeInquiryProducts([]))}} >Clear Tray</div>} */}
            {orderType == "Store Inquiry" && <div className="clear-tray" onClick={()=>{dispatch(addStoreInquiryProducts([]))}}>Clear Tray</div>}
          </div>
          <div className="frames">
            {orderType == "Home Inquiry" && [...homeInquiry.products].reverse().map((product, index)=>(
              <div className="frame-card" key={index}>
                <div className="img-box">
                  <img src={product.images && product.images.length > 0 ? product.images[0].replace("upload/", "upload/if_w_gt_300/w_300/if_end/") : ""} alt={product.name} />
                </div>
                <div className='product'>
                  <div className="">
                  <div className='product-name'>{product?.name}</div>
                  <div className='product-name'>{product?.title?.en}</div>
                  <div className='product-sku'>sku:&nbsp;{product?.sku}</div>
                  </div>
                  <div className='d-flex j-sb mt-10'>
                    <div className="status">{product?.phoneNumber}</div>
                    <div className='product-return-quantity'><div className="return"></div><div className="quantity"></div></div>
                  </div>
                  <div className='product-return-quantity'><div className="return" 
                  onClick={()=>{dispatch(removeProductFromHomeInquiry(product.product_id))}}
                  >Remove</div></div>
                </div>
                </div>
            ))}
            {orderType == "Store Inquiry" && [...storeInquiry.products].reverse().map((product, index)=>(
              <div className="frame-card" key={index}>
                <div className="img-box">
                  <img src={product.images && product.images.length > 0 ? product.images[0].replace("upload/", "upload/if_w_gt_300/w_300/if_end/") : ""} alt={product?.name} />
                </div>
                <div className='product'>
                  <div className="">
                  <div className='product-name'>{product?.name}</div>
                  <div className='product-name'>{product?.title?.en}</div>
                  <div className='product-sku'>sku:&nbsp;{product?.sku}</div>
                  </div>
                  <div className='d-flex j-sb mt-10'>
                    <div className="status">{product?.phoneNumber}</div>
                    <div className='product-return-quantity'><div className="return"></div><div className="quantity"></div></div>
                  </div>
                  <div className='product-return-quantity'><div className="return" 
                  onClick={()=>{dispatch(removeProductFromStoreInquiry(product._id))}}
                  >Remove</div></div>
                </div>
                </div>
            ))}
  
  
          </div>
          <div className="add" onClick={()=>{setShowAddProductsPanel(true)}}>
            <div className="plus" ><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M450-450H220v-60h230v-230h60v230h230v60H510v230h-60v-230Z"/></svg></div>
            <div className="text">Add Frame</div>
          </div>
          <div className="first d-flex j-sb pointer">
            <div className="title" onClick={()=>{
              console.log("view tray")
              props.setShowInquiryReview(true)}
              
              }>View Tray</div>
            <div className="close-btn">{`>`}</div>
          </div>
        </div>
        <div className="left-pane" style={{display:showAddProductsPanel?"flex":"none"}}>
          <div className="navigation">
            <div className="left pointer" onClick={()=>{setShowAddProductsPanel(false)}}>
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M400-107.692 27.692-480 400-852.308l42.539 42.539L112.769-480l329.77 329.769L400-107.692Z"/></svg>
              <div className="label mont-bold" >Product Details</div>
            </div>
          </div>
          <div className="add-products">
            {orderType == "Home Inquiry" && <VirtualProducts products={products} setProducts={setProducts}/>}
            {orderType == "Store Inquiry" && <VirtualProductsForStoreInquiry products={products} setProducts={setProducts}/>}
          </div>
        </div>  
      </div>
    )
  }
  
  function VirtualProducts(props){
    //login
    const [user, setUser] = useState(getUser());
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const homeInquiry = useSelector((state) => state.homeInquiry);
    useEffect(()=>{
      if(!user){
        navigate('/login')
      }
    },[user])
    //login end
  
    const [newData, setNewData] = useState();
    const [products, setProducts] = useState([]);
  
    const [page, setPage] = useState(1);
    const { data:productsData, isLoading, isFetching } = useGetProductsQuery(page);
  
    useEffect(() => {
      if(productsData){
        setNewData(productsData)
      }
    }, [productsData])
    useEffect(() => {
      if(newData){
        setProducts([...products, ...newData.products])
      }
    }, [newData])
  
  useEffect(() => {
    const scrollContainer = document.querySelector('.add-products');
  
    if (!scrollContainer) return;
  
    const handleScroll = () => {
      
      if (scrollContainer.scrollTop + scrollContainer.clientHeight >= scrollContainer.scrollHeight-50 && !isFetching) {
        setPage(prevPage => prevPage + 1);
      }
    };
  
    scrollContainer.addEventListener('scroll', handleScroll);
    return () => scrollContainer.removeEventListener('scroll', handleScroll);
  }, [isFetching]);
  
  const ProductCard = ({product}) => {
    const isInCart = homeInquiry.products.some(item => item.product_id === product._id);
    const homeInquiryCustomer = useSelector((state) => state.homeInquiry.customer);
    return(
      <div className="card" key={product._id}>
        <div className="top">
          {(isInCart ?
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="m422.46-323.69 255.85-255.85L650-607.85 422.46-380.31l-114-114L280.15-466l142.31 142.31ZM480.13-120q-74.67 0-140.41-28.34-65.73-28.34-114.36-76.92-48.63-48.58-76.99-114.26Q120-405.19 120-479.87q0-74.67 28.34-140.41 28.34-65.73 76.92-114.36 48.58-48.63 114.26-76.99Q405.19-840 479.87-840q74.67 0 140.41 28.34 65.73 28.34 114.36 76.92 48.63 48.58 76.99 114.26Q840-554.81 840-480.13q0 74.67-28.34 140.41-28.34 65.73-76.92 114.36-48.58 48.63-114.26 76.99Q554.81-120 480.13-120Zm-.13-40q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
          :<svg onClick={()=>{
            if(!homeInquiryCustomer){
              
              toast.error("Please enter customer details ",{
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce
              })
              return
            }
            // max 30 products can be added into inquiry
            if(homeInquiry.products.length >= 30){
              toast.error("Maximum 30 products can be added into inquiry ",{
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce
              })
              return
            }

            dispatch(addHomeInquiryProducts([...homeInquiry.products ,{
            images: product.image,
            variants: product.variants,
            attributes: product.attributes,
            orderStatus: "Placed",
            product_id: product._id,
            name: product.title.en,
            sku: product.sku,
            isCombination: product.isCombination,
            quantity: 1,
            price: product.prices.price,
            inquiryStatus: "Placed",
            phoneNumber: homeInquiryCustomer.phone,
            customerId: homeInquiryCustomer._id
          }]))}} className='pointer' xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M460-300h40v-160h160v-40H500v-160h-40v160H300v40h160v160Zm20.13 180q-74.67 0-140.41-28.34-65.73-28.34-114.36-76.92-48.63-48.58-76.99-114.26Q120-405.19 120-479.87q0-74.67 28.34-140.41 28.34-65.73 76.92-114.36 48.58-48.63 114.26-76.99Q405.19-840 479.87-840q74.67 0 140.41 28.34 65.73 28.34 114.36 76.92 48.63 48.58 76.99 114.26Q840-554.81 840-480.13q0 74.67-28.34 140.41-28.34 65.73-76.92 114.36-48.58 48.63-114.26 76.99Q554.81-120 480.13-120Zm-.13-40q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
          )}
        </div>
        <div className="middle">
          <img src={product.image?.[0] ? product.image?.[0].replace("upload/", "upload/if_w_gt_400/w_400/if_end/") : "/Netre-logo.svg" } className='product-image' alt="Product" title="Product" loading='lazy'/>
        </div>
        <div className="bottom">
        {product.title && <div className={props.storeType === "Virtual" ? "title qty-present" : "title"}>{product.title?.en}</div>}
        {product.name && <div className={props.storeType === "Virtual" ? "title qty-present" : "title"}>{product?.name}</div>}
        <div className="sku show qty-present">SKU : {product.sku}</div>
        </div>
        {/* {Number(product?.stock) < 1  && <div className="sold-out" > 
          <div className="div">SOLD OUT</div>
        </div>} */}
      </div>
    )
  }
  
  return(
    <div className="cards" style={{
      gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    }}>
    {isLoading && <div>Loading..</div>}
    {products && products.length === 0 && <div>No products available </div>}
    {products && products.map((item)=>(
      <ProductCard product={item} key={item._id}/>
    )
    )}
    </div>
  )
  }

  function VirtualProductsForStoreInquiry(props){
    //login
    const [user, setUser] = useState(getUser());
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const storeInquiry = useSelector((state) => state.storeInquiry);
    const storeInquiryCustomer = useSelector((state) => state.storeInquiry.customer);
    useEffect(()=>{
      if(!user){
        navigate('/login')
      }
    },[user])
    //login end
  
    const [newData, setNewData] = useState();
    const [products, setProducts] = useState([]);
  
    const [page, setPage] = useState(1);
    const { data:productsData, isLoading, isFetching } = useGetProductsQuery(page);
  
    useEffect(() => {
      if(productsData){
        setNewData(productsData)
      }
    }, [productsData])
    useEffect(() => {
      if(newData){
        setProducts([...products, ...newData.products])
      }
    }, [newData])
  
  useEffect(() => {
    const scrollContainer = document.querySelector('.add-products');
  
    if (!scrollContainer) return;
  
    const handleScroll = () => {
      
      if (scrollContainer.scrollTop + scrollContainer.clientHeight >= scrollContainer.scrollHeight-50 && !isFetching) {
        setPage(prevPage => prevPage + 1);
      }
    };
  
    scrollContainer.addEventListener('scroll', handleScroll);
    return () => scrollContainer.removeEventListener('scroll', handleScroll);
  }, [isFetching]);
  
  const ProductCard = ({product}) => {
    const isInCart = storeInquiry.products.some(item => item.product_id === product._id);
    return(
      <div className="card" key={product._id}>
        <div className="top">
          {(isInCart ?
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="m422.46-323.69 255.85-255.85L650-607.85 422.46-380.31l-114-114L280.15-466l142.31 142.31ZM480.13-120q-74.67 0-140.41-28.34-65.73-28.34-114.36-76.92-48.63-48.58-76.99-114.26Q120-405.19 120-479.87q0-74.67 28.34-140.41 28.34-65.73 76.92-114.36 48.58-48.63 114.26-76.99Q405.19-840 479.87-840q74.67 0 140.41 28.34 65.73 28.34 114.36 76.92 48.63 48.58 76.99 114.26Q840-554.81 840-480.13q0 74.67-28.34 140.41-28.34 65.73-76.92 114.36-48.58 48.63-114.26 76.99Q554.81-120 480.13-120Zm-.13-40q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
          :<svg onClick={()=>{
            if(!storeInquiryCustomer){
              toast.error("Please enter customer details ",{
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce
              })
              return
            }
            // max 30 products can be added into inquiry
            if(storeInquiry.products.length >= 30){
              toast.error("Maximum 30 products can be added into inquiry ",{
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce
              })
              return
            }
            dispatch(addStoreInquiryProducts([...storeInquiry.products ,{
              images: product.image,
              variants: product.variants,
              attributes: product.attributes,
              orderStatus: "Placed",
              product_id: product._id,
              name: product.title.en,
              sku: product.sku,
              isCombination: product.isCombination,
              quantity: 1,
              price: product.prices.price,
              inquiryStatus: "Placed",
              phoneNumber: storeInquiryCustomer.phone,
              customerId: storeInquiryCustomer._id
            }
          ]))}} className='pointer' xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M460-300h40v-160h160v-40H500v-160h-40v160H300v40h160v160Zm20.13 180q-74.67 0-140.41-28.34-65.73-28.34-114.36-76.92-48.63-48.58-76.99-114.26Q120-405.19 120-479.87q0-74.67 28.34-140.41 28.34-65.73 76.92-114.36 48.58-48.63 114.26-76.99Q405.19-840 479.87-840q74.67 0 140.41 28.34 65.73 28.34 114.36 76.92 48.63 48.58 76.99 114.26Q840-554.81 840-480.13q0 74.67-28.34 140.41-28.34 65.73-76.92 114.36-48.58 48.63-114.26 76.99Q554.81-120 480.13-120Zm-.13-40q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
          )}
        </div>
        <div className="middle">
          <img src={product.image?.[0] ? product.image?.[0].replace("upload/", "upload/if_w_gt_400/w_400/if_end/") : "/Netre-logo.svg" } className='product-image' alt="Product" title="Product" loading='lazy'/>
        </div>
        <div className="bottom">
        {product.title && <div className={props.storeType === "Virtual" ? "title qty-present" : "title"}>{product.title?.en}</div>}
        {product.name && <div className={props.storeType === "Virtual" ? "title qty-present" : "title"}>{product?.name}</div>}
        <div className="sku show qty-present">SKU : {product.sku}</div>
        </div>
        {/* {Number(product?.stock) < 1  && <div className="sold-out" > 
          <div className="div">SOLD OUT</div>
        </div>} */}
      </div>
    )
  }
  
  return(
    <div className="cards" style={{
      gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    }}>
    {isLoading && <div>Loading..</div>}
    {products && products.length === 0 && <div>No products available </div>}
    {products && products.map((item)=>(
      <ProductCard product={item} key={item._id}/>
    )
    )}
    </div>
  )
  }

  